import { CapitalOneLogoPrimaryDark, Flex, Box } from '@cof/plastic-components';
import PropType from 'prop-types';

import Logo from '../Logo';
import AggregatorLogo from './AggregatorLogo';

const LogoComponent = ({ showAggregatorLogo = false, flexibleUI }) => {
    return (
        <Flex
            width="100%"
            height="100%"
            justifyContent={flexibleUI ? ['flex-start', 'flex-start', 'center'] : ['center', null, 'flex-start']}
            alignItems="center"
            data-qa-id="logo-container"
            paddingLeft={flexibleUI ? ['sm', null, null] : null}
        >
            {showAggregatorLogo ? (
                <Box style={{ borderRight: '1px', paddingRight: '10px', borderColor: '#000', borderStyle: 'solid' }}>
                    <CapitalOneLogoPrimaryDark />
                </Box>
            ) : (
                <Logo flexibleUI={flexibleUI} />
            )}
            <AggregatorLogo />
        </Flex>
    );
};

LogoComponent.propTypes = {
    showAggregatorLogo: PropType.bool,
    appForm: PropType.bool,
    flexibleUI: PropType.bool
};

export default LogoComponent;
