/* eslint-disable react/jsx-props-no-spreading */
import {
    BrandedLogo,
    CapitalOneLogoPrimaryLight,
    LittlewoodsLogoPrimary,
    LumaLogoPrimaryDark,
    OceanLogoPrimary,
    ThinkMoneyLogoPrimaryDark,
    VeryLogoPrimary,
    PostOfficeLogoCore,
    ASOSLogoPrimary
} from '@cof/plastic-components';

import styled from 'styled-components';

const PostOfficeLogoCoreStyled = styled(PostOfficeLogoCore)`
    div {
        width: inherit;
        height: inherit;
    }
`;

const brandConfig = {
    capitalOne: CapitalOneLogoPrimaryLight,
    littlewoods: ({ props }) => (
        <LittlewoodsLogoPrimary width={['174px', null, '220px']} height={['24px', null, '30px']} {...props} />
    ),
    luma: ({ props }) => (
        <LumaLogoPrimaryDark width={['111px', null, '112px']} height={['24px', null, '26px']} {...props} />
    ),
    // ocean: OceanLogoPrimary,
    ocean: ({ props }) => (
        <OceanLogoPrimary width={['auto', null, '140px']} height={['20px', null, 'auto']} {...props} />
    ),
    thinkmoney: ({ props }) => (
        <ThinkMoneyLogoPrimaryDark width={['132.21px', null, '157px']} height={['32px', null, '38px']} {...props} />
    ),
    postoffice: ({ flexibleUI, props }) => (
        <PostOfficeLogoCoreStyled
            width={['41.85px', null, '150px']}
            height={['32px', null, flexibleUI ? '48px' : '100px']}
            {...props}
        />
    ),
    very: ({ props }) => <VeryLogoPrimary width={['40px', null, '44px']} height={['40px', null, '44px']} {...props} />,
    asos: ({ props }) => <ASOSLogoPrimary width={['90px', null, '150px']} {...props} />
};

// pass null for the allowList as this will cause it to pick up the allowList from the top level brand provider
const Logo = (props) => <BrandedLogo brandConfig={brandConfig} allowList={null} {...props} />;

export default Logo;
